import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h1>
    <p>{`Welcome to Planday, we're happy to see you here. You'll start building powerful integrations to Planday, by using our API, in no time! Read about the basics below and then browse through the documentation to learn more about integrating to Planday and explore our APIs.`}</p>
    <h3 {...{
      "id": "1-get-started-with-planday"
    }}>{`1. Get started with Planday`}</h3>
    <ul>
      <li parentName="ul">{`Request a demo portal which you can use for development and testing of your integration. To request a demo portal, fill the following `}<a parentName="li" {...{
          "href": "https://forms.gle/D8eASsTeJGKCoNYd7"
        }}>{`form`}</a>{`. `}</li>
    </ul>
    <h3 {...{
      "id": "2-learn-about-our-api-basics"
    }}>{`2. Learn about our API basics`}</h3>
    <ul>
      <li parentName="ul">{`Access to the Planday's API is secured by a standard Oauth2 flow with a bearer token. Learn more under `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/gettingstarted/authorization-flow"
        }}>{`Authentication`}</a>{`. Choose whether users should connect your integration manually via Settings in Planday or using our auth flow for a more seamless experience.`}</li>
      <li parentName="ul">{`Planday's REST api follows the standard structure of handling entity ID in the JSON response model. All request and response data have to be in JSON. Our API is separated into several domains (HR, Absence etc.). Explore all domains and endpoints by navigating to the `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/absence"
        }}>{`API section`}</a>{`.`}</li>
      <li parentName="ul">{`Most endpoints come with pagination and return a max of 50 records per page. If you handle your API calls using offset, you should be good to go.`}</li>
      <li parentName="ul">{`If you’d like to get our Postman collection, request it by emailing us at `}<a parentName="li" {...{
          "href": "mailto:apisupport@planday.com"
        }}>{`apisupport@planday.com`}</a>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "3-set-up-your-test-app-for-openapi-access"
    }}>{`3. Set up your test app for OpenAPI access`}</h3>
    <ul>
      <li parentName="ul">{`Once you get access to your portal, navigate to Settings → API access and use the Create app button to create an application you will use for development and testing. If you already know what scopes your integration needs, choose only the desired scopes. If you are unsure what scopes will be necessary, choose all scopes for your test app (new scopes cannot be added once the app is created) and don't create your production app yet. Once you're done with development, go back and evaluate what is the minimal list of scopes your production app will require.`}</li>
      <li parentName="ul">{`Authorize the app on the portal and start with development. `}</li>
      <li parentName="ul">{`In case of any questions or issues, reach out to us at `}<a parentName="li" {...{
          "href": "mailto:apisupport@planday.com"
        }}>{`apisupport@planday.com`}</a>{`.`}</li>
      <li parentName="ul">{`Use our `}<a parentName="li" {...{
          "href": "https://help.planday.com/en/articles/3702459-getting-started-set-up-your-business"
        }}>{`Set Up Your Business guide`}</a>{` to make the portal your own.`}</li>
    </ul>
    <h3 {...{
      "id": "4-become-a-certified-partner-applies-to-integrations-intended-for-general-availability-not-custom-integrations"
    }}>{`4. Become a certified partner (applies to integrations intended for general availability, not custom integrations)`}</h3>
    <p>{`If you'd like to become an Integration Partner, fill out the following `}<a parentName="p" {...{
        "href": "https://forms.gle/rxFxAr6qV44X19aD7"
      }}>{`form`}</a>{`. One of our partnership managers will reach out to you shortly to book a demo where you’ll be asked to show the integration’s setup process and functionality.`}<br />{`
Read more about the process on the `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/gettingstarted/become-an-integration-partner"
      }}>{`dedicated page`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      